
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 5% 2rem; 
}

  

.nav-container{
  display: flex;
  align-items: center;
  
}

.nav-container h1{
   margin-left: 1rem;
   font-size: 1.5rem;
   border-bottom: .17rem solid var(--main-color);
   display: inline-block;
}

.back-link {
  text-decoration: none;
  color: var(--main-color);
  background: transparent;
  border: none;
 font-size: 3rem;
 
}

.main-description{
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: center;
  
}

.description-text{
  position: relative;
  left: 5rem;
  top:-2rem;
  width: 45rem;
}



.imagine-serviciu{
  width: 20rem;
  position: relative;
  top:-5rem
   
}

 
.imagine-serviciu img{
  width: 100%;
  opacity: 0;
  animation: zoomIn 1s ease forwards, floatImage 2s ease-out infinite ;
  animation-delay: 0.5s;
 -webkit-filter: drop-shadow(19px 19px 19px var(--main-color));
 filter: drop-shadow(19px 19px 19px var(--main-color));
}



.card-image{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: -1rem;
  width:40rem;
 
}

.main_image  {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 35rem;
  height: 25rem;
  

}

.main_image img{
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
  
  object-fit:cover;
  min-width: 100%;
  height: 100%;
}

.previous-btn, .next-btn{
  position: relative;
  font-size: 3rem;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  cursor: pointer;
}

.previous-btn{
  left: -1rem;
}
.next-btn{
  right: -1rem;
}

.previous-btn:disabled,
.next-btn:disabled {
  opacity: 0.2;
 
}


.thumbnail_images {
  width: 35rem;
  margin-top: 2rem;
}

.thumbnail_images ul {
  display: flex;
  justify-content: center;
  list-style: none;
  transition: all 0.5s;
  align-items: center;

}



.thumbnail_images ul li img {
  width:70px;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
  height:52.5px;
  padding: .5rem;
  margin-left: .3rem;
  margin-right: .3rem;
}

.thumbnail_images ul li img:hover {
  border: 2px solid var(--main-color);
} 












.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
}

.modal-content {
  position: relative;
  background-color: rgba(0, 0, 0, 1);
  max-width: 600px;
  padding: 1rem 2rem;
  text-align: center;
}

.modal-image img {
  max-width: 100%;
  height: auto;
  max-height: 400px;
}

.close {
  position: relative;
  top: 10px;
  left: 20rem;
  font-size: 3rem;
  cursor: pointer;
}

.modal-navigation {
  margin-top: 20px;
}

.previous-button,
.next-button {
  padding: 10px 20px;
  font-size: 3rem;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  cursor: pointer;
}

.previous-button:disabled,
.next-button:disabled {
  opacity: 0.2;
 
}


/* Smartphone Potrait */
@media  screen and (max-width:480px) {
  
  .imagine-serviciu{
    display: none;
  }
  .nav-container h1{
    
    line-height: 1;
    margin-left: 1rem;
    font-size: 1.2rem;
    border-bottom: .17rem solid var(--main-color);
    display: inline-block;
    
  }
  .description-text{
    position:relative;
    
    left: 1rem;
    top:1rem;
    width: 20rem;
    right: 1rem;
    margin-bottom: 6rem;
  }
  
  .main_image  {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 20rem;
   height: 15rem;
  
    
  
  }

  .main_image img{
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  
  .thumbnail_images {
    width: 25rem;
    margin-top: 2rem;
   
  }
  
  .thumbnail_images ul {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: center;
    list-style: none;
    transition: all 0.5s;
    align-items: center;
    justify-items: center;
  
  }
  
  
  
  .thumbnail_images ul li img {
    width:70px;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
    height:52.5px;
    padding: .5rem;
    margin-left: .3rem;
    margin-right: .3rem;
  }


  
  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    width: 600px;
    height: 400px;
    padding: 1rem 2rem;
    text-align: center;
    margin-top: -130px;
  }
  
  .modal-image img {
    min-width: 100%;
    object-fit: cover;
    min-height: 100%;
  }
  
  .close {
    position: relative;
    top: -50px;
    left: 150px;
    font-size: 3rem;
    cursor: pointer;
  }
  
 
  
  
   }

       /* Smartphone Landscape mobile */
@media only screen 
and (max-device-width: 414px) 
and (device-height: 896px) 
and (-webkit-device-pixel-ratio: 2) 
and (orientation: landscape) {

  .nav-container h1{
    
    line-height: 1;
    margin-left: 1rem;
    font-size: 1.5rem;
    border-bottom: .17rem solid var(--main-color);
    display: inline-block;
    
  }
  .description-text{
    position:relative;
    
    left: 1rem;
    top:1rem;
    width: 28rem;
    right: 1rem;
    margin-bottom: 6rem;
  }

  .imagine-serviciu{
    width: 15rem;
    position: relative;
    top:-5rem
     
  }
  
   
  .imagine-serviciu img{
    width: 100%;
    opacity: 0;
    animation: zoomIn 1s ease forwards, floatImage 2s ease-out infinite ;
    animation-delay: 0.5s;
   -webkit-filter: drop-shadow(19px 19px 19px var(--main-color));
   filter: drop-shadow(19px 19px 19px var(--main-color));
  }

  .main_image  {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 25rem;
   height: 20rem;
  
    
  
  }

  .main_image img{
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  
  .thumbnail_images {
    width: 25rem;
    margin-top: 2rem;
   
  }
  
  .thumbnail_images ul {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    justify-content: center;
    list-style: none;
    transition: all 0.5s;
    align-items: center;
    justify-items: center;
  
  }
  
  
  
  .thumbnail_images ul li img {
    width:70px;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
    height:52.5px;
    padding: .5rem;
    margin-left: .3rem;
    margin-right: .3rem;
  }
  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    min-width: 400px;
    min-height: 200px;
    padding: 1rem 2rem;
    text-align: center;
    margin-top: -10px;
  }
  
  .modal-image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
    
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: -340px;
    cursor: pointer;
  }
  

  
  .previous-button,
  .next-button {
    position: absolute;
    font-size: 3rem;
    background-color: transparent;
    border: none;
    color: var(--main-color);
    cursor: pointer;
  }

  
.previous-button{
  left: -7rem;
  top:12rem;

}
.next-button{
  right: -7rem;
  top:12rem
}


  
  .previous-button:disabled,
  .next-button:disabled {
    opacity: 0.2;
   
  }
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 9999;
  }

}

/* Tablet Portrait */
@media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait)  {

  .nav-container h1{
    
    line-height: 1;
    margin-left: 1rem;
    font-size: 1.5rem;
    border-bottom: .17rem solid var(--main-color);
    display: inline-block;
    
  }
  .description-text{
    position:relative;
    
    left: 1rem;
    top:1rem;
    width: 28rem;
    right: 1rem;
    margin-bottom: 12rem;
  }

  .imagine-serviciu{
    width: 15rem;
    position: relative;
    top:-7rem
     
  }
  
   
  .imagine-serviciu img{
    width: 100%;
    opacity: 0;
    animation: zoomIn 1s ease forwards, floatImage 2s ease-out infinite ;
    animation-delay: 0.5s;
   -webkit-filter: drop-shadow(19px 19px 19px var(--main-color));
   filter: drop-shadow(19px 19px 19px var(--main-color));
  }

  .main_image  {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 30rem;
   height: 25rem;
  
    
  
  }

  .main_image img{
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  
  .thumbnail_images {
    width: 25rem;
    margin-top: 2rem;
   
  }
  
  .thumbnail_images ul {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    justify-content: center;
    list-style: none;
    transition: all 0.5s;
    align-items: center;
    justify-items: center;
  
  }
  
  
  
  .thumbnail_images ul li img {
    width:70px;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
    height:52.5px;
    padding: .5rem;
    margin-left: .3rem;
    margin-right: .3rem;
  }
  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    min-width: 400px;
    min-height: 200px;
    padding: 1rem 2rem;
    text-align: center;
    margin-top: -10px;
  }
  

}
  