.home {
    
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7rem 10% 7rem;
   

}

.home-img img{
    opacity: 0;
    animation: zoomIn 1s ease forwards, floatImage 2s ease-out infinite ;
    animation-delay: 0.5s;
    width: 40rem;
   -webkit-filter: drop-shadow(19px 19px 19px var(--main-color));
   filter: drop-shadow(19px 19px 19px var(--main-color));
 }

.home-content h2 {
    font-size: 2rem;
    font-weight: 700;
    opacity: 0;
   animation: slideBottom .5s ease forwards;
   animation-delay: .7s;
}

.home-content h3{
    margin-bottom: 1.3rem;
    font-size: 1.8rem;
    animation: slideTop .5s ease forwards;
   animation-delay: .7s;
}
.home-content h3 span {
    color: #0ef;
}

.home-content h1 {
    font-size: 2.8rem;
    font-weight: 700;
    /* margin: -3px 0; */
    opacity: 0;
    animation: slideRight .5s ease forwards;
    animation-delay: 1s;
}

.home-content p {
    font-size: 1rem;
    margin-top: .7rem;
    opacity: 0;
    animation: slideLeft .5s ease forwards;
    animation-delay: 1s;
}

.home-content h1{
    color: var(--main-color);
}

.banner {
    width: 100%;
    height: 100%;
    margin-top: 4rem;
}


 /* Smartphone Potrait */
 @media  screen and (max-width:480px) {
    .home{
        padding: 7rem 1rem 7rem;
        flex-direction: column;
        height: fit-content;
    }
    .home-content .btn{
        display: none;
    }
    .home-content h1{
        font-size: 2rem;
    }
    .home-img {
        width: 25rem;
        margin-top: 2.5rem;

    }
    .home-img img{
        width: 100%;
    }
    .banner {
        margin-top: 5rem;
    }
 }

/* Smartphone Landscape mobile  */



@media  screen and (max-width:896px)
and (max-height:414px) {
    .home {
    
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7rem 10% 7rem;
       
    
    }
    
    .home-img img{
        opacity: 0;
        animation: zoomIn 1s ease forwards, floatImage 2s ease-out infinite ;
        animation-delay: 0.5s;
        width: 20rem;
       -webkit-filter: drop-shadow(19px 19px 19px var(--main-color));
       filter: drop-shadow(19px 19px 19px var(--main-color));
     }

     .home-content{
        padding-top: 3rem;
        padding-bottom: 3rem;
     }
    
    .home-content h2 {
        font-size: 1.3rem;
        font-weight: 700;
        opacity: 0;
       animation: slideBottom .5s ease forwards;
       animation-delay: .7s;
       margin-top: 3rem;
    }
    
    .home-content h3{
        margin-bottom: 1.3rem;
        font-size: 1.3rem;
        animation: slideTop .5s ease forwards;
       animation-delay: .7s;
    }
    .home-content h3 span {
        color: #0ef;
    }
    
    .home-content h1 {
        font-size: 1.8rem;
        font-weight: 700;
        /* margin: -3px 0; */
        opacity: 0;
        animation: slideRight .5s ease forwards;
        animation-delay: 1s;
    }
    
    .home-content p {
        font-size: 1rem;
        margin-top: .7rem;
        opacity: 0;
        animation: slideLeft .5s ease forwards;
        animation-delay: 1s;
    }
    
    .home-content h1{
        color: var(--main-color);
    }

    }

     /* Tablet Portrait */
 @media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait)  {
    .home{
        padding: 3rem 5% 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .home-content .btn{
        display: none;
    }
    .home-img {
        width: 30rem;

    }
    .home-img img{
        width: 100%;
    }
    .home-content h2{
        font-size: 1.5rem;
    }
    .home-content h1{
        font-size: 1.7rem;
    }
    .home-content h3{
        font-size: 1.5rem;
    }
    .home-content p{
        font-size: 1.2rem;
        padding-right: 1rem;
    }
   
 }

    /* Tablet Landscape */

@media only screen and  (max-device-width: 1366px) and (orientation: landscape)  {
 .home{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 5% 0;
 }
 .home-img {
    width: 40rem;

}

}