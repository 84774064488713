*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--bg-color);
  color: var(--text-color);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;1,700&display=swap');




:root{
  --bg-color:#1f242d;
  --text-color: #fff;
  --main-color:#0ef;
  --second-bg-color: #323946;
  --white-color:#fdfdfd;
  --shadow-color:rgba(0,0,0,0.2);
}




