
.nav {
    position: fixed ;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 10%;
   
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: var(--bg-color);
  box-shadow:  0 .1rem 1rem var(--shadow-color);
}

.logo {
    font-size: 1.5rem;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
    opacity: 1;
    animation: slideRight 1s ease forwards;
}

.navbar {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 100%;
}

 .about-nav,.services, .active,.portfolio,.contact {
    display: inline-block;
   font-size: 1rem;
   color: var(--text-color);
   text-decoration: none;
   font-weight: 500;
   margin-left: 1.1rem;
   opacity: 1;
   animation: slideTop .5s ease forwards;
   animation-delay: calc(.2s * var(--i));
   cursor: pointer;
}

.navbar a:hover,
.navbar a.active {
    color: var(--main-color);
}





.mobile-menu-icon {
    display: none;
}




/* Mobile View */
@media  screen and (max-width:780px) {
.logo {
    display: flex;
    position: relative;
    top:5px;
    left: -5px;
    font-size: 1.5rem;
}

.navbar{
   display: none;
}


.mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 2rem;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    outline: none;
    top: 1rem;
    right:1.5rem;
}

.navbar-mobile{
    position: absolute;
    display: block;
    list-style: none;
    background-color: var(--second-bg-color) ;
    left: 0;
    top: 4.1rem;
    transition: all 0.5 ease-out;
    width: 100%;
}

.active,
.about-nav,
.portfolio,
.contact,
.services {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--main-color);
    font-size: 1rem;
    text-decoration: none;
    padding-top: 1rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    width: 100%;
    transition:  all 0.5 ease;
}
}



    /* Smartphone Landscape mobile */
    @media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 896px) 
    and (orientation: landscape) 
    and (-webkit-device-pixel-ratio: 2) {
    .nav  {
        width: 100vw;
    }
    .logo{
        font-size: 1rem;
    }

    .about-nav,
    .services, 
    .active,
    .contact {
        font-size: 1.2rem;
    }


}




/* Medium view */
@media  screen and (max-width:1024px) {
    .about-nav,.services, .active,.portfolio,.contact {
        font-size: 1rem;
        width: 100%;
    }
}
/* Tablet Potratit */
@media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait)  {
    .logo{
        font-size: 1.2rem;
        width: 100%;
    }
}

/* animation key */
@keyframes slideRight {
    0% { 
        transform: translateX(-100px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }


}
@keyframes slideLeft {
    0% { 
        transform: translateX(100px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }


}
@keyframes slideTop {
    0% { 
        transform: translateY(100px);
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }


}

@keyframes slideBottom {
    0% { 
        transform: translateY(-100px);
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }


}
@keyframes zoomIn {
    0% { 
        transform: scale(0);
        opacity: 0;
    }
    100% { 
        transform: scale(1);
        opacity: 1;
    }


}

@keyframes floatImage {
    0% { 
        transform: translateY(0);
        
    }
    50% { 
        transform: translateY(24px);
        
    }
    100% { 
        transform: translateY(0);
        
    }


}
