.about{
   padding: 0rem 10% 10rem;
   display: flex;
   flex-direction: column;
}
.heading {
   font-size: 2.5rem;
   text-align: center;
}
span{
   color: var(--main-color);
}

.about-desc {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 3rem;
   width: 100%;
}

.about-img {
   width: 50%;
}

.about-img img{
    
    width: 100%;
   -webkit-filter: drop-shadow(19px 19px 19px var(--main-color));
   filter: drop-shadow(19px 19px 19px var(--main-color));
 }

 .about-content{
   width: 50%;
 }




 .about-content h3{
    font-size: 1.1rem;
    margin: 7rem 0 1rem 0;
 }

 .about-content p{
    font-size: .99rem;
    margin: 1rem 0 1rem 0;
   
 }

 



 /* Smartphone Potrait */
 @media  screen and (max-width:480px) 
 {
   
   .about{
      padding: 0 5% 3rem;
   }

   .about-desc {
   flex-direction: column-reverse;
  
}
.about-img{
   width: 90%;
}


 }

 
  /* Smartphone Landscape mobile  */



@media  screen and (max-width:896px)
and (max-height:414px) {
   .about{
      padding: 0 5% 0;
   }
   
   .about-desc{
      flex-direction: row;
    
   }
   .about-content{
      width: 100%;
   }

   .about-content p {
      font-size: .5rem;
      margin: 0;
   }
   .heading {
      padding-top: 6rem;
   }
   .about-content h3{
      font-size: 1.1rem;
      margin: 3rem 0 1rem 0;
   }
  
}



  

/* Tablet Landscape */

@media only screen and  (max-width: 1194px)
  {
  .about{
   padding: 2rem 5% 2rem;
  }
  .about-desc {
   flex-direction: row-reverse;
  
   
  
}






}

/* Tablet Portrait */
@media only screen and (max-width: 834px) 
and (max-height: 1194px)   {

   .about{
      padding: 0 5% 3rem;
   }

.about-desc
  {
   flex-direction: column-reverse;
  
  }

.about-content{
   width: 100%;
}

}