.contactare{
    padding: 7rem 10% 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
   
 }
 .form-text {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    color: lightgray;
}

 .contact-desc{
    display: flex;
    gap: 2rem;
    width: 100%;
    justify-content: space-evenly;
    

 }

 .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    row-gap: 1rem;
    margin-top: 5rem;
    
}

 .social-contact{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     row-gap: .7rem;
    width: 50%;
 }
 


.icon{
    width: 25rem;
    display: flex;
    border-radius: 2rem;
    background: transparent;
    border: .1rem solid var(--main-color);
    font-size: 2rem;
    align-items: center;
    padding: .2rem 0;
    justify-content: center;
    box-shadow: 3px 0px 20px var(--main-color);
    
}




.icon a {
    color: var(--main-color);
    text-decoration: none;
}

.icon i {
    color: var(--main-color);
    margin-right: .4rem;
}

.icon span {
    font-size: 1rem;
    
}

.info h3{
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
}


.contact-form{
    width: 50%;
    margin-top: 5rem;
}
.contact-form form{
    
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}



.contact-form form input {
    background-color: var(--bg-color);
    border: .1rem solid var(--main-color);
    border-radius: 5rem;
    height: 3rem;
    color: var(--main-color);
    padding-left: 1rem;
    font-size: 1.2rem;
}

input::placeholder {
    font-size: 1rem;
    color: var(--main-color);
}


.contact-form form textarea{
    background-color: var(--bg-color);
    border: .1rem solid var(--main-color);
    border-radius: 2rem;
    height: 15rem;
    color: var(--main-color);
    padding-left: 1rem;
    padding-top: 1rem;
    font-size: 1.1rem;
    resize: none;
}

textarea::placeholder {
    color: var(--main-color);
}

.submit {
    color: var(--bg-color);
    background-color: var(--main-color);
    font-size: 1.2rem;
    width: 7rem;
    padding: .5rem;
    box-shadow: 0 0 2rem var(--main-color);
    border: none;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    font-weight: 600;
    letter-spacing: .03rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.submit:hover{
    color: var(--main-color);
    background-color: var(--bg-color);
 }

.btn-up {
   position: relative;
    margin-left: auto;
    margin-bottom: .7rem;
        margin-top: 4rem;
}
 .btn-up i{
    cursor: pointer;
    color: var(--main-color);
    font-size: 3rem;
   
   
 }

 .btn-up i:hover{
   transform: scale(1.05);
   
 }

 .design{
     position: relative;
    display: flex;
    flex-direction: column;
   
 }

 .copy-right{
     margin-left: auto;
    margin-right: auto; 
    font-size: 0.8rem;
 }



 .design-link{
     margin-bottom:0; 
    margin-left: auto;
    margin-right: auto; 
    font-weight: 300;
    font-size: .9rem;
 }

 .design-link a{
    text-decoration: none;
    color: var(--main-color);
 }







 /* Tablet Landscape */

@media only screen and  (max-device-width: 1194px)  {
  .form-text{
    font-size: 1.1rem;
  }
  .contactare{
    padding: 5rem 5% 0rem;
  }
}

/* Tablet Portrait */
@media only screen and (max-device-width: 834px)  {
   
    .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 400px;
        row-gap: 1rem;
        margin-top: 5rem;
        
    }
    
    .social-contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        row-gap: .7rem;
       width: 50%;
    }
    
    .icon{
        width: 23rem;
        display: flex;
        border-radius: 2rem;
        background: transparent;
        border: .1rem solid var(--main-color);
        font-size: 2rem;
        align-items: center;
        padding: .2rem 0;
        justify-content: center;
        box-shadow: 3px 0px 20px var(--main-color);
        
    }
    
    .icon a {
        color: var(--main-color);
        text-decoration: none;
    }
    
    .icon i {
        color: var(--main-color);
        margin-right: .4rem;
    }
    
    .icon span {
        font-size: 1rem;
        
    }
  
    
    
    .design{
     margin-bottom:2rem; 
    margin-left: auto;
    margin-right: auto; 
    font-weight: 300;
    font-size: .9rem;
 }
}

 /* Smartphone Potrait */
 @media  screen and (max-width:480px) {
    .contact-desc{
        flex-direction: column;
      
    }

   
    .contact-form{
        width: 100%;
        margin-top: 5rem;
    }

    .info{
        width: 100%;
        margin-left: .6rem;
       
    }
    .icon{
    width: 22rem;

    
}

    .btn-up{
        margin-left: auto;
    }
    .form-text{
        padding: 0 5%;
        text-align: center;
        color: lightgray;
        font-size: 1rem;
    }
    .social-media {
       
        justify-content: center;
    }
    
 }



  /* Smartphone Landscape mobile Iphone 11 */

  @media  screen and (max-width:896px)
  and (max-height:414px) {
    .contactare{
        padding: 0rem 5% 0rem;
    }
 
    .form-text{
        font-size: 1.1rem;
      }
 
  
  }
