.servicii {
    padding: 7rem 10% 7rem;
   
    
}

.servicii .services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10% 0;
    justify-items:center;
    align-items: center;
    row-gap: 2rem;
}

.services-container .services-box {
   
    background-color: var(--bg-color);
    padding:  3rem 2rem 4rem;
    border-radius: 2rem;
    box-shadow:  0 .1rem .5rem var(--shadow-color);
    text-align: center;
    border-top: .6rem solid var(--main-color);
    border-bottom: .6rem solid var(--main-color);
    width: 20rem;
    height: 17rem;
    transition: .5 ease;
}

.services-container .services-box:hover {
    transition: .5s ease-in;
    transform: scale(1.1);
     
}

.sapaturi{
    
    height: fit-content;
}


@keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .services-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 39px;
    margin-top: 35px;
}

.services-box i {
    font-size: 5rem;
    margin-bottom: 1rem;
    color: var(--main-color);
    animation: rotate 5s infinite ;

}


.services-box img{
    width: 5rem;
    margin-bottom: 1rem;
    animation: rotate 5s infinite ;
    
}

.services-box h3 {
    font-size: 1rem;
    margin-bottom: 1.4rem;
    transition: .5s ease;
}

.servicii h2{
    margin-bottom: 5rem;
}

.link-pages{
    color: var(--main-color);
    text-transform: none;
    text-decoration: none;
}


/* Tablet Landscape */

@media only screen and  (max-device-width: 1366px) and (orientation: landscape)  {
    .servicii .services-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items:center;
        padding: 0 10% 0;
        justify-content: center;
        align-self: center;
        gap: 2rem;
    }
    
    .services-container .services-box {
        width: 20rem;
        height: 20rem;
    } 
    .services-box img{
        width: 5rem;
        margin-bottom: .5rem;
        
    }

    .services-box h3 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        transition: .5s ease;
    }
  
    .heading {
        font-size: 2.2rem;
    }
    .servicii{
        padding: 5rem 10% 5rem;
    }
}

 /* Tablet Portrait */
 @media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait)  {
    .servicii .services-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items:center;
        padding: 0 10% 0;
        justify-content: center;
        align-self: center;
        gap: 2rem;
    }
    .services-container .services-box {
        width: 20rem;
        height: 20rem;
    } 
   
     .services-box h3 {
        font-size: 1.1rem;
        margin-bottom: 1rem;
       
    }
    .services-box i {
        font-size: 6rem;
        margin-bottom: 1rem;
    }
 }

 /* Smartphone Potrait */
@media  screen and (max-width:414px)
and (max-height:896px) {
    .servicii .services-container {
        display: grid;
        grid-template-columns: 1fr;
        
        justify-items:center;
        align-items: center;
        row-gap: 2rem;
    }
    .servicii{
        padding: 2rem 0 2rem;
    }
}

    /* Smartphone Landscape mobile Iphone 11 */

    @media  screen and (max-width:896px)
    and (max-height:414px) {

    
        .servicii .services-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 0 5% 0;
            justify-items:center;
            align-items: center;
            column-gap: .5rem;
        }
        
        .services-container .services-box {
            width: 16rem;
            height: 13rem;
        } 
        .services-box img{
            width: 3rem;
            margin-bottom: .5rem;
            
        }
    
        .services-box h3 {
            font-size: .9rem;
            margin-bottom: 1rem;
            transition: .5s ease;
        }
        .services-box i {
            font-size: 3rem;
            margin-bottom: 1rem;
        }
      
        .servicii{
            padding: 0rem 5% 0rem;
        }
        
    }
